import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  Input,
  Loader,
  Select,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import { FILTER } from "../../constant";
import { dateFormatter, seperator, showToaster } from "../../helperFunctions";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { useWalletData } from "./walletSlice";
import { useGetWalletMutation } from "./walletApi";

const Wallet = () => {
  const walletData = useWalletData();
  const [getWallet, { isLoading }] = useGetWalletMutation();
  const initialDate = {...FILTER/* ,date: [new Date(), new Date()] */}
  const [filter, setFilter] = useState<any>(initialDate);

  const {
    control,
    formState: { errors },
    register,
    getValues,
    handleSubmit,
    reset,
  } = useForm(/* {
    defaultValues: {
      date: [new Date(), new Date()],
    },
  } */);

  const columns = [
    { title: "ID", name: "id" },
    { title: "Merchant ID", name: "merchantId" },
    {
      title: "Merchant Name",
      name: "merchantId",
      Cell: (data: any) => {
        return <span>{data?.merchantDetails?.merchant_name}</span>;
      },
    },
    {
      title: "Credit Amount",
      name: "creditAmt",
      Cell: (data: any) => {
        return <span>{seperator(data?.creditAmt)}</span>;
      },
    },
    { title: "IS Approved", name: "isApproved",Cell:(data:any) => (
      <>
      <div>{data?.isApproved ? 'Yes' : 'No'}</div>
      </>
    ) },
    { title: "Date", name: "createdAtIst" },
    { title: "Remark", name: "remark" },
  ];

  const onGetData = async () => {
    try {
      const { remark, date, ...rest } = filter;
      const payload = {
        ...rest,
        filter_data:{
          remark: remark,
          InitiateDate: dateFormatter(date?.[0], "start"),
          FinalizeDate: dateFormatter(date?.[1], "end"),

        }
      };
      delete payload["isFilter"];

      const res: any = await getWallet(payload).unwrap();

      showToaster(res?.message);
    } catch (err: any) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...FILTER,
      isFilter: true,
    });
  };

  return (
    <>
      <form
        className="flex flex-wrap gap-2 items-start px-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          className="me-2"
          name="remark"
          placeholder="Search By Remark"
          label="Search"
          register={register}
        />
        <ControlledDatePicker
          name="date"
          label="Select Date"
          placeholder="Date"
          options={{
            mode: "range",
          }}
          control={control}
          errors={errors}
        />
        <Button type="submit" className="mt-[22px] !h-9 !text-sm">
          Apply
        </Button>
        <Button
          type="button"
          variant="Transaparent"
          className="mt-[22px] !h-9 !text-sm"
          onClick={() => {
            reset();
            setFilter(initialDate);
          }}
        >
          Clear
        </Button>
      </form>

      <div className="App mt-3">
        <Table
          columns={columns}
          isLoading={isLoading}
          data={walletData?.data}
          count={walletData?.total_item}
          pagination={filter}
          isExpendable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
        >
          {walletData?.data?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow columns={columns} item={item} isExpendable={false} />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default Wallet;
