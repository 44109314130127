import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { walletApi } from "./walletApi";

interface WalletState {
  WalletDataState: any;
  DownloadWallet: any;
}

const initialState: WalletState = {
  WalletDataState: {},
  DownloadWallet: {},
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    clearWallet: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      walletApi.endpoints.getWallet.matchFulfilled,
      (state, { payload }) => {
        state.WalletDataState = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default walletSlice.reducer;
export const { clearWallet } = walletSlice.actions;

export const selectWalletData = (state: RootState) =>
  state.wallet.WalletDataState;
export const useWalletData = () => {
  const walletData = useSelector(selectWalletData);
  return useMemo(() => walletData, [walletData]);
};
