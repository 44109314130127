import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import { Button, Icon, Table, TableRow } from "../../ui";

// PROJECT IMPORT
import { useLazyGetBulkUploadQuery } from "./bulkUploadApi";
import { FILTER } from "../../constant";
import { useBulkUploadData } from "./bulkUploadSlice";
import EditFileBulkModal from "./EditFileBulkModal";
import BulkUploadModal from "./BulkUploadModal";
import Test from "../../assets/pdfs/test.csv";
import WithdrawalAddModel from "../withdrawal/WithdrawalAddModel";

const Bulkupload = () => {
  const bulkUploadData = useBulkUploadData();
  const [showMsg, setShowMsg] = useState(false);

  const [filter, setFilter] = useState(FILTER);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalBulkOpen, setIsModalBulkOpen] = useState(false);
  const [isEditModalBulkOpen, setIsEditModalBulkOpen] = useState(false);
  const [isAddModalBulkOpen, setIsAddModalBulkOpen] = useState(false);

  const [editId, setEditId] = useState();

  const [getBulkUpload] = useLazyGetBulkUploadQuery();

  const onEditClick = (isModal: any, id: any) => {
    setIsEditModalBulkOpen(isModal);
    setEditId(id);
  };

  const columns = [
    { title: "File Name", name: "file_name" },
    { title: "No Of Txn", name: "txn_no" },
    { title: "Pending", name: "pending_no" },
    { title: "Success", name: "success_no" },
    {
      title: "Action",
      Cell: (data: any) => {
        return (
          <div className="flex items-center">
            <Button
              className="me-4 !h-7 !text-sm"
              href={`${process.env.REACT_APP_BASE_URL}/withdrawal/bulk/file/${data?.id}`}
              download={data?.file_name}
            >
              Download
            </Button>
            {data?.pending_no ? (
              <div
                className=" flex items-center justify-center bg-[#dadada88] rounded-full h-[30px] w-[30px] cursor-pointer"
                onClick={() => {
                  onEditClick(true, data?.id);
                }}
              >
                <Icon
                  name="Edit"
                  className="width-[15px] !fill-chatlook-darkblue"
                />
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  const onGetData = async () => {
    try {
      setIsLoading(true);
      const payload: any = {
        page: filter?.page_no,
        limit: filter?.limit,
      };
      await getBulkUpload(payload).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error("Bulk Upload Get Error =-=>", err);
    }
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (showMsg) {
        setShowMsg(false);
      }
    }, 2000);
    return () => clearInterval(IntervalClick);
  }, [showMsg]);

  return (
    <>
      <div className="text-end w-full my-4">
        <div className="flex flex-wrap gap-2 justify-end">
          <Button href={Test} className=" !h-8 !text-sm !px-2">
            Download CSV Template
          </Button>
          <Button
            className="!h-8 !text-sm !px-2"
            onClick={() => setIsModalBulkOpen(!isModalBulkOpen)}
          >
            Bulk Upload
          </Button>
          {/* <div>
            <Button
              className="whitespace-nowrap !h-8 !text-sm !px-2"
              type="button"
              onClick={() => {
                setIsAddModalBulkOpen(!isAddModalBulkOpen);
              }}
            >
              Add Withdrawal
            </Button>
          </div> */}
        </div>
      </div>

      <div>
        <div className="App">
          <h2 className="text-ev-darkblue table-heading">
            {/* Recent Balance Sheet */}
          </h2>
          <Table
            columns={columns}
            isLoading={isLoading}
            isExpendable={false}
            data={bulkUploadData?.data}
            count={bulkUploadData?.total}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
              });
            }}
          >
            {bulkUploadData?.data?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
        <BulkUploadModal
          isOpen={isModalBulkOpen}
          onClose={() => setIsModalBulkOpen(false)}
          setShowMsg={setShowMsg}
          showMsg={showMsg}
        />
        <EditFileBulkModal
          isOpen={isEditModalBulkOpen}
          onClose={() => {
            setIsEditModalBulkOpen(false);
          }}
          editId={editId}
        />
        <WithdrawalAddModel
          onClose={() => setIsAddModalBulkOpen(false)}
          isOpen={isAddModalBulkOpen}
        />
      </div>
      {showMsg ? (
        <div className="fixed flex items-center justify-center top-0 left-0 h-full w-full bg-opacity-20 bg-black z-50">
          <Icon name="success" className="gif-success"></Icon>
        </div>
      ) : null}
    </>
  );
};

export default Bulkupload;
