// UI IMPORT
import { ToasterTypes } from "./ui";

// PROJECT IMPORT
import { clearAuth } from "./features/auth/authSlice";
import { clearBalanceSheet } from "./features/balanceSheet/balanceSheetSlice";
import { clearBulkUpload } from "./features/bulkUpload/bulkUploadSlice";
import { clearCommon } from "./features/common/commonSlice";
import { clearDashboard } from "./features/dashboard/dashboardSlice";
import { clearDeposite } from "./features/deposite/depositeSlice";
import { clearReport } from "./features/report/reportSlice";
import { clearUsers } from "./features/users/usersSlice";
import { clearWithdrawal } from "./features/withdrawal/withdrawalSlice";
import { AppDispatch } from "./utills/store";

// THIRD - PARTY IMPORT
import { toast } from "react-hot-toast";

const root = window.document.documentElement;

export const kFormatter = (num: any) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, "") + "K";
  }
  return num;
};

export const logout = async (dispatch: AppDispatch) => {
  dispatch(clearAuth());
  dispatch(clearBalanceSheet());
  dispatch(clearBulkUpload());
  dispatch(clearCommon());
  dispatch(clearDashboard());
  dispatch(clearDeposite());
  dispatch(clearReport());
  dispatch(clearUsers());
  dispatch(clearWithdrawal());
};

export const downloadFile = (url: any, type: string, name: string) => {
  let element = document.createElement("a");
  let file = new Blob([url], { type: `${type}/*` });
  element.href = URL.createObjectURL(file);
  element.download = name;
  element.click();
};

export const showToaster = (
  message: string,
  type: ToasterTypes = "Success"
) => {
  switch (type) {
    case "Error":
      toast.error(message || "Something Went Wrong!", {
        position: "top-right",
      });
      break;
    case "Success":
      toast.success(message, {
        position: "top-right",
      });
      break;
    default:
      toast.success(message, {
        position: "top-right",
      });
      break;
  }
};

export const changeFavicon = (newFaviconUrl: any) => {
  const favicon: any = window.document.querySelector('link[rel="icon"]') || {};
  favicon.href = newFaviconUrl;
};

export const setStyles = (varr: string, color: string) => {
  root?.style.setProperty(varr, color);
};

export const thousandSeparator = (x: any, rupee: any = true) => {
  const f = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  const val = f.format(+x || 0)?.toString();

  const newValues = val?.split(".");
  let newVal = val;
  if (newValues?.length === 2 && newValues?.at(-1) === "00") {
    newVal = newValues?.[0];
  }

  return rupee ? newVal : newVal?.slice(1);
};

export const dateFormatter = (d: any, type: any = "start") => {
  if (!d) {
    return null;
  }
  const date = new Date(d);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day} ${type === "end" ? "23:59:59" : "00:00:00"}`;
};

export const onFixed = (value: any, decimal = 2) => {
  if (!+value) {
    return 0;
  }

  return +(+value)?.toFixed(decimal);
};

export const seperator = (amount: any, isRupee = true) => {
  if (amount?.toString()?.includes(",")) {
    return `${isRupee ? "₹ " : ""}${amount}`;
  }

  const isNagative = amount?.toString()?.[0] === "-";
  const initialZeroes = Array.from({ length: 2 })?.reduce((t) => t + "0", "");

  const f = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 2,
  });

  let newAmount = f.format(onFixed(+Math.abs(+amount)) || 0);

  const newAmounts = newAmount?.split(".");
  const isAllZeores = (newAmounts?.[1] || "") === initialZeroes;

  if (isAllZeores) {
    newAmount = newAmounts?.[0];
  }
  newAmount = `${isNagative ? "₹ -" : ""}${
    isRupee && !isNagative ? "₹ " : ""
  }${newAmount?.slice(1)}`;

  return newAmount;
};
