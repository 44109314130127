import { api } from "../../utills/api";

export const walletApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWallet: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/wallet",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetWalletMutation } = walletApi;
export const {
  endpoints: { getWallet },
} = walletApi;
