// UI IMPORT
import { Avatar, Button, Input } from "../../ui";

// PROJECT IMPORT
import {
  useGetSettingDetailsQuery,
  useUpdateSettingSummaryMutation,
  useUpdateSettingWebSummaryMutation,
} from "./SettingApi";
import { useUser } from "../auth/authSlice";
import Doc from "../../assets/pdfs/PGMINE-API.pdf";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { showToaster } from "../../helperFunctions";
import { useSettingDetails } from "./SettingSlice";

const Setting = () => {
  const user = useUser();
  const details = useSettingDetails();
  const { register, handleSubmit } = useForm({});
console.log(details,"details");

  const prepareDefaultValues = () => {
    if (details) {
      return {
        deposit_notify: details?.deposit_webhook,
        withdrawal_notify: details?.withdrawal_webhook,
      };
    } else {
      return {};
    }
  };

  const { register: registerweb, handleSubmit: handleWebSubmit } = useForm<any>(
    {
      defaultValues: {
        ...prepareDefaultValues(),
      },
    }
  );

  const [updateSettingSummary] = useUpdateSettingSummaryMutation();
  useGetSettingDetailsQuery();

  const [updateSettingWebSummary] = useUpdateSettingWebSummaryMutation();

  const onSubmit = async (values: any) => {
    try {
      const res: any = await updateSettingSummary(values).unwrap();
      showToaster(res?.message);
    } catch (err) {
      console.error("Change Password Error =-=>", err);
    }
  };

  const onSubmitLink = async (values: any) => {
    try {
      const res: any = await updateSettingWebSummary(values).unwrap();
      showToaster(res?.message);
    } catch (err) {
      console.error("Web Hook Error =-=>", err);
    }
  };

  return (
    <>
      <div className="grid 2xl:grid-cols-2 lg:grid-cols-2  xl:grid-cols-2 md:grid-cols-1 gap-4">
        <div className="bg-chatlook-white pass-mainsec rounded-xl">
          <form key={1} onSubmit={handleSubmit(onSubmit)}>
            <Input
              className="!w-full mb-4"
              name="oldPwd"
              placeholder="Enter Old Password"
              label="Old Password"
              register={register}
            />
            <Input
              className="!w-full mb-4"
              name="newPwd"
              placeholder="Enter New Password"
              label="New Password"
              register={register}
            />
            <Input
              className="!w-full mb-4"
              name="confirmPwd"
              placeholder="Enter Confirm Password"
              label="Confirm New Password"
              register={register}
            />
            <Button type="submit" className="m-auto my-8">
              Apply
            </Button>
          </form>
          <h2>Webhook</h2>
          <form key={2} onSubmit={handleWebSubmit(onSubmitLink)}>
            <Input
              className="!w-full mb-4"
              name="deposit_notify"
              placeholder="Enter Deposit Webhook"
              label="Deposit Webhook"
              register={registerweb}
            />
            <Input
              className="!w-full mb-4"
              name="withdrawal_notify"
              placeholder="Enter Withdrawal Webhook"
              label="Withdrawal Webhook"
              register={registerweb}
            />
            <Button type="submit" className="m-auto my-8">
              Submit
            </Button>
          </form>
        </div>
        <div className="bg-chatlook-lightblue profile-secmain rounded-xl">
          <div className="text-center profile-detail">
            <div className="bg-gradient-to-b from-blue-800 to-blue-400 !h-[145px] !w-[145px] rounded-full flex items-center justify-center m-auto">
              <Avatar
                name={user?.userData?.fullName || ""}
                className="!text-[75px] !h-[135px] !w-[135px]"
              />
            </div>
            {/* <Icon name="Profile" className="m-auto" /> */}
            <h4 className="text-ev-darkblue">
              {user?.userData?.fullName || ""}
            </h4>
            <p className="text-ev-lightblue">{user?.userData?.email || ""}</p>
          </div>
          <div className="flex grid-cols-2 justify-center">
            <div className="user-status rounded-xl ">
              <p className="text-ev-lightblue">
                <b className="text-ev-darkblue">User Name : </b>
                {user?.userData?.fullName || ""}
              </p>
              <p className="text-ev-lightblue">
                <b className="text-ev-darkblue">MID : </b>
                {user?.userData?.merchant_id || ""}
              </p>
              <p className="text-ev-lightblue">
                <b className="text-ev-darkblue">Status : </b>
                <span className="text-ev-green">
                  {user?.userData?.accountStatus || ""}
                </span>
              </p>
            </div>
          </div>
          {/* <div className="flex grid-cols-2 justify-center pt-3">
            <Button href={Doc} download="PgminDoc.pdf">
              Integration Doc
            </Button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Setting;
